@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');


@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
  }


  .paygreen-pan-frame iframe,
  .paygreen-exp-frame iframe,
  .paygreen-cvv-frame iframe {
    padding-left: 10px;
  }

  .paygreen-pan-frame,
  .paygreen-exp-frame,
  .paygreen-cvv-frame {
    border: #f8fff5;
    height: fit-content;
  }

  .paygreen-pan-frame:hover,
  .paygreen-exp-frame:hover,
  .paygreen-cvv-frame:hover {
    border-bottom: #185e43 solid 1px;
  }


}
